<template>
    <div class="body">
        <notification-popup
            :dialog="notifGetFailed"
            :contentMsg="$t('PleaseTryAgain')"
            :headerMsg="$t('FailedGetListNotification')"
            :nameBtnNo="$t('Close')"
            :nameBtnYes="$t('Reload')"
            colorIcon="danger"
            typeModal="transaction"
            Icon="mdi-close-circle"
            imgSource="notification/failed.svg"
            :onHandlerYes="buttonReload"
            :onHandlerNo="buttonClose"
        />
        <div id="spacer"></div>
        <v-container>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <label class="label-content">{{$t('EnableNotification')}}</label>
                <v-switch
                    :value="toggleStatus"
                    :input-value="toggleStatus"
                    @change="updateStatusNotif()"
                    inset
                    class="ma-0 pa-0"
                ></v-switch>
            </div>
        </v-container>
    </div>
</template>

<script>
import NotificationPopup from "@/components/NotificationPopup.vue";

export default {
    name: 'NotificationSetting',
    components: {
        NotificationPopup
    },
    data() {
        return {
            toggleStatus: false,
            notifGetFailed: false
        }
    },
    methods: {
        async getStatusNotif() {
            const payload = {
                fcm_token: localStorage.getItem('fcmToken')
            }
            await this.$store
                .dispatch("customer/GetStatSubsNotif", payload)
                .then((response) => {
                    if (response.status) {
                        this.toggleStatus = response.data.status
                    }
                })
                .catch((err) => {
                    console.log(err);
                    // this.notifGetFailed = true
                });
        },
        async updateStatusNotif() {
            const payload = {}
            await this.$store
                .dispatch("customer/UpdateSubsNotif", payload)
                .then((response) => {
                    if (response.status) {
                        this.toggleStatus = response.data
                    }
                })
                .catch((err) => {
                    console.log(err);
                    // this.notifGetFailed = true
                });
        },
        buttonReload() {
            this.getStatusNotif()
            this.notifGetFailed = false
        },
        buttonClose() {
            this.notifGetFailed = false
        },
    },
    mounted() {
        this.getStatusNotif()
    },
}
</script>

<style scoped>
#spacer {
    padding: 6px;
}

.body {
    background-color: #FFF;
    height: 100%;
    width: 100%;
}

.label-content {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}
</style>